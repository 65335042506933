/* =================================== */
/*	Basic Style
/* =================================== */
body {
   background-color: #fff;
   font-family: "Open Sans", sans-serif;
   font-size: 14px;
   color: #6a737b;
}
ol,
ul {
   margin: 0;
   padding: 0;
   list-style: none;
}
figure,
p {
   margin: 0;
}
a {
   color: #fff;

   -webkit-transition: all 0.3s ease-in 0s;
   -moz-transition: all 0.3s ease-in 0s;
   -ms-transition: all 0.3s ease-in 0s;
   -o-transition: all 0.3s ease-in 0s;
   transition: all 0.3s ease-in 0s;
}
iframe {
   border: 0;
}
a,
a:focus,
a:hover {
   text-decoration: none;
   outline: 0;
}
a:focus,
a:hover {
   color: #f7e27e;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: normal;
   margin: 0;
}
.clear:before,
.clear:after {
   content: " ";
   display: table;
}
.clear:after {
   clear: both;
}
.clear {
   *zoom: 1;
}
span.color {
   color: #0feb9e;
}
body > section {
   /* padding: 70px 0; */
   margin: -1px 0;
}
.footer {
   background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
   color: #fff;
   margin: -1px 0;
   padding: 10px 0;
}
.sec-title {
}
.sec-title h2 {
   color: #f7e27e;
   font-size: 28px;
   font-weight: 800;
   text-transform: uppercase;
}
.sec-sub-title {
   margin: 35px 0 45px;
}
.sec-sub-title p {
   line-height: 24px;
   font-size: 14px;
   color: #5b646e;
}
.sec-sub-title h4 {
   color: #f7e27e;
   margin: 2em 0 0.5em 0;
   font-weight: 600;
   font-size: 21px;
}
.devider {
   margin-top: 30px;
}
.devider i {
   color: #cccccc;
}
.devider:before,
.devider:after {
   content: "__________________";
   color: #e6e8ea;
   position: relative;
   bottom: 6px;
}
.devider:before {
   right: 10px;
}
.devider:after {
   left: 10px;
}
.mb50 {
   margin-bottom: 50px;
}
#preloader {
   background-color: #fff;
   height: 100%;
   position: fixed;
   width: 100%;
   z-index: 1100;
   overflow: hidden;
}
#preloader > img {
   left: 47%;
   position: absolute;
   top: 48%;
}
/*=========================================
	Header
==========================================*/
.navbar-logo {
   height: 100%;
}
.navbar-nav li a.current {
   border-top: none !important;
}
#navigation {
   background-color: rgba(16, 22, 54, 0.2);
   border: 0 none;
   margin: 0;

   -webkit-transition: background-color 800ms linear;
   -moz-transition: background-color 800ms linear;
   -ms-transition: background-color 800ms linear;
   -o-transition: background-color 800ms linear;
   transition: background-color 800ms linear;
}
.navbar-toggle i {
   color: #fff;
}
.navbar-brand {
   display: flex;
   align-items: center;
   padding: 8px;
}
.navbar-brand h1 {
   font-size: 27px;
   font-family: "Pathway Gothic One", sans-serif;
}
.navbar-brand a:focus,
.navbar-brand a:hover {
   color: #ffffff;
}
.tron {
   color: #c23631;
}
.sub-logo {
   color: #f7e27e;
}
.navbar-nav li a {
   border-top: 1px solid transparent;
}
.navbar-nav li a.current,
.navbar-nav li a:focus,
.navbar-nav li a:hover {
   background-color: transparent;
   border-top: 1px solid #32b0ee;
   color: #fff;
}
.social-links {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: fixed;
   bottom: 25vh;
   left: 0;
   z-index: 100;
   background: #35307b;
   width: 3.4rem;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
}
.social-links li {
   display: inline-block;
   margin: 5px 0px;
}
.social-links li a {
   color: #010101;
   display: block;
   width: 3.2rem;
   height: 3.2rem;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.social-links li a:hover {
   color: #fff;
}
.social-links i {
   font-size: 2rem;
}
.linktr {
   width: 3rem;
   height: 3rem;
}
/*=========================================
        Features
    ==========================================*/
.features {
   background: radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%);
   margin-top: -1px;
   padding-top: 120px;
}
.owl-controls {
   bottom: -45px;
   margin-top: 10px;
   position: relative;
   text-align: center;
}
.owl-controls .owl-page {
   display: inline-block;
}
.owl-controls .owl-page span {
   border: 1px solid #c7ced3;
   border-radius: 20px;
   display: block;
   height: 8px;
   margin: 5px 4px;
   width: 8px;
}
.owl-controls .owl-page.active span {
   background: #062033;
   border: 0;
}
.service-item-shadow {
   filter: drop-shadow(0px 0px 10px #ebd378)
      drop-shadow(0px 0px 40px rgb(247 226 126 / 18%));
   margin: 120px 66px;
}
.service-item-wrapper {
   background-color: #ebd378;
   border-radius: 76px;
   padding: 5px;
}
.service-item {
   border-radius: 76px;
   padding: 60px;
   padding-top: 25px;
   background-color: #010101;
   /* margin-top: 10px; */
   text-align: center;
   /* filter: blur(115px) drop-shadow(-10px -10px 75px #ff00ff); */
}
.service-icon {
   /* border: 1px solid #f7e27e; */
   border-radius: 50%;
   /* color: #f7e27e; */
   /* float: left; */
   padding: 10px 10px;

   text-align: center;
}
.service-icon img {
   width: 241px;
   height: 241px;
   border-radius: 50%;
}
.service-desc {
   /* margin-left: 70px; */
   position: relative;
   top: 5px;
   margin-bottom: 30px;
}
.service-desc h3 {
   color: #f7e27e;
   font-size: 4rem;
   font-weight: 600;
   margin-bottom: 25px;
   text-align: center;
}
.service-desc p {
   color: #f4f7f9;
   font-size: 2rem;
}
.service-tools {
   padding-top: 10px;
   padding-bottom: 20px;
}
.btn-gold {
   display: inline-block;
   outline: none;
   font-family: inherit;
   font-size: 1.28em;
   letter-spacing: 1px;
   box-sizing: border-box;
   border: none;
   border-radius: 0.3em;
   height: 2.75em;
   line-height: 2.5em;
   text-transform: uppercase;
   padding: 0 1em;
   box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(110 80 20 / 40%),
      inset 0 -2px 5px 1px rgb(139 66 8), inset 0 -1px 1px 3px rgb(250 227 133);
   background-image: linear-gradient(
      160deg,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07
   );
   border: 1px solid #a55d07;
   color: rgb(120, 50, 5);
   text-shadow: 0 2px 2px rgb(250 227 133);
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   background-size: 100% 100%;
   background-position: center;
}
.btn-gold:hover {
   background-size: 150% 150%;
   box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%),
      inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgb(250 227 133);
   border: 1px solid rgba(165, 93, 7, 0.6);
   color: rgba(120, 50, 5, 0.8);
}
/*=========================================
	deposits
==========================================*/
.project-wrapper {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}
.pdf-wrap {
   position: relative;
   margin: 20px 0;
}
.bg-blur {
   /* filter: blur(8px);
   -webkit-filter: blur(6px); */
   width: 100%;
   padding: 28%;
   box-sizing: border-box;
   background-image: url("pdf.2adc10d5.jpg");
   background-repeat: no-repeat;
   background-size: contain;
}
.bg-blur.bg-audit {
   background-image: url("Audit.ee806567.jpg");
   background-size: cover;
}
.bg-blur-text {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   color: #fff;
   border: #fff solid 4px;
   padding: 20px;
   min-width: 230px;
   text-align: center;
   background-color: rgba(0, 0, 0, 0.8);
}
.modal-title {
   font-size: 2em;
}
/*=========================================
	Some fun facts
==========================================*/
.counters-item {
   color: #fff;
}
.counters-item i {
   border: 1px solid #737c85;
   border-radius: 50%;
   color: #fff;
   display: inline-block;
   height: 120px;
   margin: 0 0 4px;
   padding: 40px 0 0;
   width: 120px;
}
.counters-item strong {
   display: block;
   font-size: 50px;
   font-weight: 600;
   line-height: 60px;
}
.counters-item p {
   font-size: 18px;
   line-height: 24px;
   margin-top: 15px;
   text-transform: uppercase;
}
/*=========================================
	Contact Us
==========================================*/
.contact {
   padding-bottom: 0;
}
.contact-address h3 {
   color: #062033;
   font-size: 22px;
   line-height: 32px;
   margin-bottom: 25px;
}
.contact-address p {
   line-height: 24px;
}
.contact-form {
   max-width: 350px;
   width: 100%;
   margin-bottom: 20px;
}
.contact-form h3 {
   color: #062033;
   font-size: 24px;
   font-weight: 700;
   line-height: 32px;
   margin-bottom: 25px;
}
.dep-form-wrap {
   display: flex;
   justify-content: center;
   margin-bottom: 5rem;
}
.contact-form .form-control {
   text-align: center;
}
.contact-form .input-field {
   width: 100%;
}
.contact-form .form-control.error {
   border-bottom-color: #c0392b;
}
label.error {
   color: #c0392b;
   font-weight: normal;
   text-transform: capitalize;
}
.contact-form #form-submit {
   color: #000;
   font-size: 16px;
   line-height: 24px;
   padding: 10px;
   width: 150px;
   outline: none;
   background: #ffffff;
}
.inline-help {
   padding-left: 2px;
   color: #6a737b;
   font-size: 1.3rem;
}
.game {
   position: absolute;
   left: 5px;
   bottom: 10px;
   width: 72px;
   font-size: 1.1em;
   color: #f7e27e;
   font-family: "Merienda", cursive;
   animation-name: glow;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
}
@keyframes glow {
   from {
      text-shadow: 0px 0px 5px #f7e27e, 0px 0px 5px #ebd451;
   }
   to {
      text-shadow: 0px 0px 20px #f7e27e, 0px 0px 20px #ebd451;
   }
}
.footer-social {
   margin-top: 17px;
}
.footer-social li a {
   color: #cdd2d6;
   display: block;
   margin-bottom: 10px;
}
/*=========================================
	Contact Us
==========================================*/
.footer {
   background-color: #1ea78d;
   color: #fff;
}
.footer-logo {
   font-size: 32px;
   font-family: "Pathway Gothic One", sans-serif;
   margin-bottom: 1rem;
}
.footer a:hover {
   color: #062033;
}
.footer h6 {
   font-size: 14px;
   font-weight: 700;
   line-height: 24px;
   margin-bottom: 16px;
}
.about-us p {
   line-height: 24px;
}
.footer-single .subscribe {
   margin-bottom: 15px;
   position: relative;
}
.subscribe #subscribe {
   background-color: transparent;
   border: 2px solid #fff;
   border-radius: 2px;
   height: 40px;
   text-indent: 10px;
   width: 100%;
}
.subscribe #subs {
   background-color: transparent;
   border: 0 none;
   font-size: 24px;
   position: absolute;
   right: 0;
   top: 6px;
}
.footer-single {
   line-height: 24px;
}
.footer-single ul {
}
.footer-single li {
   line-height: 32px;
}
.footer-single p i {
   margin: 0 10px;
}
.credit a {
   color: #062033;
}
.credit a:hover {
   color: #fff;
}
.copyright {
   color: #fff;
   margin-top: 25px;
}
#back-top {
   bottom: 20px;
   position: fixed;
   right: 25px;
   z-index: 9;
   border-radius: 50%;
   width: 43px;
   height: 43px;
   text-align: center;
   background: #f7e27e;
   color: #010101;
   box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
}
/*New-------------*/
#slider {
   height: 100vh;
   background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
   overflow: hidden;
   padding-top: 70px;
}
#slider .title-box {
   position: absolute;
   top: 50%;
   left: 0;
   right: 0;
   text-align: center;
   width: 100%;
   color: #fff;
   font-family: "lato", sans-serif;
   font-weight: 300;
   transform: translateY(-50px);
   padding: 0 10px;
}
#title {
   margin-bottom: 10vh;
}
#title .space {
   /* background: -webkit-linear-gradient(white, #38495a); */
   /* -webkit-background-clip: text;
   -webkit-text-fill-color: transparent; */
   font-size: 7vmin;
   letter-spacing: 4px;
}
.title-normal {
   font-size: 14px;
   font-weight: 600;
}
.title-normal .fa {
   color: #f7e27e;
   font-size: 1.2em;
   padding-right: 1px;
}
.btn-group {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: row;
}
.btn-group a,
.btn-group button {
   margin: 10px;
}
#read-more {
   letter-spacing: 1px;
   padding: 10px 15px;
   background: #ffffff;
   color: #222222;
   border-radius: 5px;
   font-weight: 600;
   font-size: 1.5em;
   text-decoration: none;
}
@keyframes animStar {
   from {
      transform: translateY(0px);
   }
   to {
      transform: translateY(-2000px);
   }
}
.dep-card {
   width: 100%;
   /* box-shadow: 0 8px 18px rgba(0,0,0, 0.4); */
   background-color: #ffffff;
   border-radius: 4px;
   margin: 4px 0;
   font-family: "lato", sans-serif;
   /* border-bottom: 1px solid #cecfd5; */
   border: solid 1px rgb(14, 180, 147);
   padding: 5px;
   position: relative;
}
.dep-header {
   text-align: center;
}
.dep-header .big-title {
   font-weight: 600;
   font-size: 2.2rem;
   color: rgb(14, 180, 147);
}
.dep-header .sub-title {
   font-size: 85%;
   color: #7c7c80;
}
.dep-body {
   display: flex;
   flex-direction: column;
}
.dep-props {
   padding: 4px 15px;
}
.dep-props .item-row {
   text-align: center;
   border-bottom: 1px solid #cecfd5;
   padding: 2px 0;
}
.dep-props .item-row:last-child {
   border-bottom: none;
}
.dep-props .item-cat {
   font-size: 1.05em;
   color: #7c7c80;
}
.dep-props .item-val {
   color: #000;
   font-weight: 600;
   font-size: 1.2rem;
}
.timer {
   font-size: 2em;
   color: #ff4565;
}
.dep-actions {
   display: flex;
   flex-direction: row;
   justify-content: center;
}
.dep-actions .btn {
   margin: 0.375rem;
   word-wrap: break-word;
   cursor: pointer;
   border: 0;
   text-decoration: none;
   outline: none;
   min-width: 78px;
   white-space: nowrap;
   padding: 5px 6px;
}
.dep-actions .btn:disabled {
   cursor: default;
}
.dep-actions .btn-cancel {
   border: none;
   background-color: #ffffff;
   color: #dc3545 !important;
}
.dep-actions .btn-cancel:disabled,
.dep-actions .btn-cancel[disabled] {
   color: #666666 !important;
   cursor: not-allowed;
}
.btn-outline-ok {
   color: #fff;
   background-color: #2bbbad !important;
}
.stats {
   width: 100%;
   border-radius: 4px;
   margin: 4px 0;
   font-family: "lato", sans-serif;
   /* border: solid 1px #f7e27e; */
   padding: 10px;
   height: 100%;
}
.stats .fa {
   font-size: 25px;
   margin-right: 5px;
   color: #a94442;
}
.stats-row {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   padding: 3px 0px;
}
.stats-title {
   font-size: 1.6rem;
   color: #f7e27e;
   font-weight: 600;
}
.stats-value {
   font-size: 1.6rem;
}
.stats-heading {
   color: #957e02;
   margin-bottom: 1em;
   font-weight: 600;
}
.row.h-100 {
   display: flex;
   flex-wrap: wrap;
}
.row.h-100 > [class*="col-"] {
   display: flex;
   flex-direction: column;
}
.tron-logo {
   height: 80%;
}
/*General*/
.percent:after {
   content: "%";
   padding-left: 2px;
   color: #f1cb0a;
   font-size: 1.2rem;
   font-weight: 300;
}
.trx:after {
   content: "TRX";
   color: #f1cb0a;
   font-size: 90%;
   padding-left: 2px;
   font-weight: 300;
}
.audit-link {
   display: inline-block;
   height: 128px;
   width: 128px;
   background: #f7e27e;
   color: #ffffff;
   margin-top: 4rem;
   padding: 1.5rem 1rem 1rem 1rem;
}
.audit-link:hover,
.audit-link:active {
   color: #ffffff;
}
.audit-caption {
   font-size: 16px;
   font-weight: 600;
   margin-top: 1rem;
   display: block;
}
.audit-link .fa {
   font-size: 28px;
}
#faq {
   padding-top: 60px;
   background: radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%);
}
.ph-ref-input .btn,
.ph-withdraw-award .btn {
   padding: 0;
}
.loader {
   display: none;
   position: fixed;
   bottom: 10px;
   left: 10px;
   width: 16px;
   height: 16px;
   background: rgba(255, 255, 255, 0.8) url("loader.7ff8a2ac.gif") center center
      no-repeat;
   border-radius: 15px;
   z-index: 1000;
}
.form-check {
   margin-top: 5px;
   text-align: center;
}
.form-check .fa {
   font-size: 1.8rem;
   vertical-align: middle;
}
/* Tooltip container */
.simple-tooltip {
   position: relative;
   display: inline-block;
   border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.simple-tooltip .tooltiptext {
   font-family: "Open Sans", sans-serif;
   visibility: hidden;
   width: 200px;
   background-color: black;
   color: #fff;
   text-align: center;
   padding: 10px 8px;
   border-radius: 6px;
   position: absolute;
   z-index: 1;
   line-height: 1.5;
   font-size: 14px;
   top: 100%;
   left: 50%;
   margin-left: -200px;
}
/* Show the tooltip text when you mouse over the tooltip container */
.simple-tooltip:hover .tooltiptext {
   visibility: visible;
}
.d-none {
   display: none;
}
.logo-wrap {
   height: 30vh;
   width: 30vh;
   border-radius: 50%;
   display: inline-block;
}
.main-logo {
   height: 30vh;
   width: 30vh;
   -webkit-filter: drop-shadow(0px 0px 55px rgb(244 225 127));
   border-radius: 50%;
   border: 2px solid #f7e27e;
}
/* Steps */
.disc-list {
   font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
      helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
   counter-reset: step;
   /* Allows us to show the step number */
   list-style: none;
   padding: 0;
   margin: 60px 0;
   display: flex;
   flex-direction: row;
   /* Put the discs in a row */
   flex-wrap: wrap;
   /* Allow them to wrap around as required */
   justify-content: center;
}
.disc-list li {
   counter-increment: step;
   /* Increment the list number */
   position: relative;
   width: 8.5rem;
   height: 8.5rem;
   text-align: center;
   border-radius: 50%;
   /* Make it a circle */
   background: #888;
   color: #888;
   display: flex;
   flex-shrink: 0;
   /* Don't allow the discs to squash inside the container */
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 1.5rem 1.25rem 1.5rem 1.5rem;
   /* Right margin is the normal width - the border-width */
   overflow: visible;
}
.disc-list.static li:nth-child(1) {
   background: #00afc0;
   color: #00afc0;
}
.disc-list.static li:nth-child(2) {
   background: #00a3e1;
   color: #00a3e1;
}
.disc-list.static li:nth-child(3) {
   background: #27579c;
   color: #27579c;
}
.disc-list.static li:nth-child(4) {
   background: #934fc0;
   color: #934fc0;
}
.disc-list.static li:nth-child(5) {
   background: #c04f8e;
   color: #c04f8e;
}
.disc-list.static li:nth-child(6) {
   background: #c04f5d;
   color: #c04f5d;
}
.disc-list.static li:nth-child(7) {
   background: #c07e4f;
   color: #c07e4f;
}
.disc-list.static li:nth-child(8) {
   background: #c0b54f;
   color: #c0b54f;
}
.disc-list.static li:nth-child(9) {
   background: #86793c;
   color: #86793c;
}
.disc-list.static li:nth-child(10) {
   background: #486843;
   color: #486843;
}
.disc-list li span {
   color: #fff;
   display: block;
   font-weight: bold;
   font-size: 1.1rem;
   max-width: 5rem;
   margin-left: auto;
   margin-right: auto;
}
.disc-list li span::before {
   display: block;
   font-size: 1.65rem;
   margin-bottom: 0.1rem;
   font-weight: 200;
}
#user-levels li span::before {
   content: attr(data-count);
}
.disc-list.static li span::before {
   content: attr(data-percent);
}
.disc-list li svg {
   position: absolute;
   left: -1.5rem;
   top: -1.5rem;
   bottom: -1.5rem;
   /* Same as the disc-list li margin */
   right: -1.5rem;
}
.disc-list li svg path {
   stroke: currentcolor;
}
#app-loader {
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(255, 255, 255, 0.9);
   width: 100vw;
   height: 100vh;
   z-index: 100000000;
   display: flex;
   justify-content: center;
   align-items: center;
   display: none;
}
.p-5 {
   padding: 10px;
}
.announceImage {
   text-align: center;
}
.announceImage img {
   width: 100%;
   border-radius: 20px;
   margin-bottom: 20px;
}
.announcement a {
   color: #00a3e1;
   font-weight: 600;
   font-size: 16px;
}
.announcement p {
   font-size: 14px;
}
.tel {
   width: 24px;
}
.modal-body {
   padding: 0 15px 15px;
}
#announcementModal .announceImage {
   margin: 0 -15px;
}
#announcementModal img {
   border-radius: 0;
}
#announcementModal li span {
   float: right;
   font-weight: 600;
}
#announcementModal2 ol {
   list-style-image: url("rocket.250f9271.png");
   list-style-position: inside;
}
#announcementModal2 .announceImage img {
   width: 50%;
}

/*# sourceMappingURL=index.28380ea6.css.map */
